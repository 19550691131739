import { render, staticRenderFns } from "./CalendarDaysMenu.vue?vue&type=template&id=496ff2c2&scoped=true&"
import script from "./CalendarDaysMenu.vue?vue&type=script&lang=js&"
export * from "./CalendarDaysMenu.vue?vue&type=script&lang=js&"
import style0 from "./CalendarDaysMenu.vue?vue&type=style&index=0&id=496ff2c2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "496ff2c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VList,VListItem,VListItemTitle,VMenu})
